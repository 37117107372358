import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/Seo/seo"
import Hero from "../components/PageParts/SingleCaseStudy/Hero"
import Intro from "../components/PageParts/SingleCaseStudy/Intro"
import Objectives from "../components/PageParts/SingleCaseStudy/Objectives"
import Prototypes from "../components/PageParts/SingleCaseStudy/Prototypes"
import Solution from "../components/PageParts/SingleCaseStudy/Solution"
import Takeaways from "../components/PageParts/SingleCaseStudy/Takeaways"
import Navigation from "../components/PageParts/SingleCaseStudy/Navigation"

const caseStudy = props => {
  const heroImg =
    props.data.study.acf._swb_cs_featured_img.localFile.childImageSharp.fluid
  const title = props.data.study.title
  const subTitle = props.data.study.acf._swb_cs_title
  const introContent = props.data.study.acf._swb_cs_intro
  const workType = props.data.study.acf._swb_cs_work_type
  const objImg =
    props.data.study.acf._swb_cs_obj_img.localFile.childImageSharp.fluid
  const objContent = props.data.study.acf._swb_cs_obj
  const protoImg =
    props.data.study.acf._swb_cs_proto_style_img.localFile.childImageSharp.fluid
  const protoContent = props.data.study.acf._swb_cs_proto_style
  const solutionImg =
    props.data.study.acf._swb_cs_sol_img.localFile.childImageSharp.fluid
  const solutionContent = props.data.study.acf._swb_cs_sol
  const takeawayImg =
    props.data.study.acf._swb_cs_take_img.localFile.childImageSharp.fluid
  const takeawayContent = props.data.study.acf._swb_cs_take
  const linkReq = props.data.study.acf._swb_cs_link_required
  const linkUrl = props.data.study.acf._swb_cs_link

  const metaImg = props.data.study.acf._swb_cs_featured_img.localFile.publicURL

  return (
    <Layout location={props.location.pathname}>
      <SEO
        title={`${title} - Case Study`}
        description={`${title} - ${subTitle}`}
        location={props.location.pathname}
        pageImg={metaImg}
      />
      <Hero img={heroImg} />
      <Intro data={{ title, subTitle, introContent, workType }} />
      <Objectives img={objImg} content={objContent} />
      <Prototypes img={protoImg} content={protoContent} />
      <Solution img={solutionImg} content={solutionContent} />
      <Takeaways
        img={takeawayImg}
        content={takeawayContent}
        linkReq={linkReq}
        linkUrl={linkUrl}
      />
      <Navigation next={props.pageContext.next} prev={props.pageContext.prev} />
    </Layout>
  )
}

export const query = graphql`
  query caseStudyQuery($slug: String!) {
    study: wordpressWpCaseStudy(slug: { eq: $slug }) {
      title
      acf {
        _swb_cs_title
        _swb_cs_work_type
        _swb_cs_link_required
        _swb_cs_link

        _swb_cs_intro
        _swb_cs_featured_img {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        _swb_cs_obj
        _swb_cs_obj_img {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        _swb_cs_proto_style
        _swb_cs_proto_style_img {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        _swb_cs_sol
        _swb_cs_sol_img {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        _swb_cs_take
        _swb_cs_take_img {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default caseStudy
