import React from "react"
import styled from "styled-components"

import {
  MedWrapper,
  headlineThree,
  bodyCopy,
  colors,
  fontSizer,
} from "../../../Utilities"

const IntroStyled = styled.div`
  .wrapper {
    ${MedWrapper}
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .meta-info {
    position: relative;
    width: 100%;
    margin-right: 2.5rem;
    margin-bottom: 5rem;

    @media (min-width: 768px) {
      align-self: flex-start;
      width: calc(33.33% - 2.5rem);
      margin-right: 2.5rem;
      margin-bottom: 0;
    }

    ul {
      li {
        ${bodyCopy};
        color: #585858;
        text-align: center;

        @media (min-width: 768px) {
          text-align: right;
        }
      }
    }

    &::after {
      display: block;
      position: absolute;
      width: 100%;
      height: 0.8rem;
      left: 0.5rem;
      bottom: -2rem;
      right: 0.5rem;
      content: "";
      background: ${colors.colorTertiary};
    }

    @media (min-width: 1025px) {
      &::after {
        display: block;
        position: absolute;
        width: 0.8rem;
        height: auto;
        top: 0.5rem;
        bottom: 0.5rem;
        right: -3rem;
        left: auto;
        content: "";
        background: ${colors.colorTertiary};
      }
    }
  }

  .content-info {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(66.66% - 2rem);
      margin-left: 2rem;
    }

    h1 {
      ${headlineThree};
      ${fontSizer(2, 4, 76.8, 150, 2.2)}
      margin: 0;
      margin-bottom: 1rem;
      color: ${colors.colorPrimary};
      text-transform: uppercase;
      text-align: center;

      @media (min-width: 768px) {
        text-align: left;
      }

      span {
        display: block;
      }
    }

    p {
      ${bodyCopy};
      color: #585858;
    }
  }
`

const Intro = props => {
  return (
    <IntroStyled>
      <div className="wrapper">
        <div className="meta-info">
          <ul>
            {props.data.workType.map((type, index) => {
              return <li key={index}>{type}</li>
            })}
          </ul>
        </div>
        <div className="content-info">
          <h1>
            {props.data.title} <span>{props.data.subTitle}</span>
          </h1>
          <div dangerouslySetInnerHTML={{ __html: props.data.introContent }} />
        </div>
      </div>
    </IntroStyled>
  )
}

export default Intro
