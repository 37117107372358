import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { MedWrapper, buttonTwo, fontSizer } from "../../../Utilities"

const NavigationStyled = styled.div`
  margin-bottom: 5rem;

  .wrapper {
    ${MedWrapper};
  }

  nav {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(66.66%);
      margin-left: calc(33.33%);
    }

    a {
      ${buttonTwo};
      ${fontSizer(1.6, 1.8, 76.8, 110, 1.8)};
      display: block;
      max-width: 25rem;
      margin: 2rem 0 0 0;
      text-align: center;

      @media (min-width: 768px) {
        display: inline-block;
        max-width: 100%;
        margin: 0 2rem;
      }
    }
  }
`

const Navigation = ({ next, prev }) => {
  return (
    <NavigationStyled>
      <div className="wrapper">
        <nav>
          {next && <Link to={`/case-studies/${next}`}>Prev Case Study</Link>}
          <Link to="/services">
            Services Page
          </Link>
          {prev && (
            <Link to={`/case-studies/${prev}`}>
              Next Case Study
            </Link>
          )}
        </nav>
      </div>
    </NavigationStyled>
  )
}

export default Navigation
