import React from "react"
import BgImg from "gatsby-background-image"
import styled from "styled-components"

import {
  MedWrapper,
  headlineThree,
  bodyCopy,
  fontSizer,
  colors,
} from "../../../Utilities"

const HeroStyled = styled.div`
  .main-featured-image {
    width: 100%;
    height: 40rem;

    @media (min-width: 768px) {
      height: 50rem;
    }

    @media (min-width: 1025px) {
      height: 70rem;
    }
  }
`

const PrototypesStyled = styled.div`
  .wrapper {
    ${MedWrapper}
    margin: 5rem auto;
  }

  .title-info {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(33.33% - 2rem);
      margin-right: 2rem;
    }

    h2 {
      ${headlineThree};
      ${fontSizer(2, 4, 76.8, 150, 2.2)}
      margin: 0;
      margin-bottom: 3rem;
      color: ${colors.colorPrimary};
      text-transform: uppercase;
      text-align: center;

      @media (min-width: 768px) {
        text-align: right;
      }

      span {
        display: block;
      }
    }
  }

  .content-info {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(66.66% - 2rem);
      margin-left: 2rem;
    }

    p {
      ${bodyCopy};
      color: #585858;
    }
  }
`

const Prototypes = props => {
  return (
    <PrototypesStyled>
      <HeroStyled>
        <BgImg className="main-featured-image" Tag="div" fluid={props.img} />
      </HeroStyled>
      <div className="wrapper">
        <div className="title-info">
          <h2>
            Prototypes <span>+ Style</span>
          </h2>
        </div>
        <div
          className="content-info"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </div>
    </PrototypesStyled>
  )
}

export default Prototypes
