import React from "react"
import BgImg from "gatsby-background-image"
import styled from "styled-components"

import {
  MedWrapper,
  headlineThree,
  bodyCopy,
  buttonTwo,
  fontSizer,
  colors,
} from "../../../Utilities"

const HeroStyled = styled.div`
  .main-featured-image {
    width: 100%;
    height: 40rem;

    @media (min-width: 768px) {
      height: 50rem;
    }

    @media (min-width: 1025px) {
      height: 70rem;
    }
  }
`

const TakeawaysStyled = styled.div`
  .wrapper {
    ${MedWrapper}
    margin: 5rem auto;
  }

  .title-info {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(33.33% - 2rem);
      margin-right: 2rem;
    }

    h2 {
      ${headlineThree};
      ${fontSizer(2, 4, 76.8, 150, 2.2)}
      margin: 0;
      margin-bottom: 3rem;
      color: ${colors.colorPrimary};
      text-transform: uppercase;
      text-align: center;

      span {
        display: block;
      }
    }
  }

  .content-info {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(66.66% - 2rem);
      margin-left: 2rem;
    }

    p {
      ${bodyCopy};
      color: #585858;
    }
  }

  .website-link {
    width: 100%;
    margin: 5rem 0 0;
    text-align: left;

    a {
      ${buttonTwo};
      ${fontSizer(1.8, 2, 76.8, 110, 2)};
    }
  }
`

const Takeaways = props => {
  const linkToWebsiteReq = props.linkReq === "yes" ? true : false

  return (
    <TakeawaysStyled>
      <HeroStyled>
        <BgImg className="main-featured-image" Tag="div" fluid={props.img} />
      </HeroStyled>
      <div className="wrapper">
        <div className="title-info">
          <h2>Takeaways</h2>
        </div>
        <div className="content-info">
          <div dangerouslySetInnerHTML={{ __html: props.content }} />
          {linkToWebsiteReq && (
            <div className="website-link">
              <a target="_blank" rel="noopener noreferrer" href={props.linkUrl}>
                View Website
              </a>
            </div>
          )}
        </div>
      </div>
    </TakeawaysStyled>
  )
}

export default Takeaways
